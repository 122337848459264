import React from "react";
import about1 from '../assets/images/new/features/1.png';
import about2 from '../assets/images/new/features/2.png';
import about3 from '../assets/images/new/features/3.png';

export default function Features({ classlist }) {
    const featuresData = [
        {
            image: about1,
            title: "Transforming Memories into Melodies",
            desc: "We turn cherished memories into personalized songs, capturing emotions and moments in a unique melody that lasts a lifetime."
        },
        {
            image: about2,
            title: "Unique Soundtrack for Every Story",
            desc: "No two stories are the same, and neither are our songs. Each composition is carefully crafted to make your story truly special."
        },
        {
            image: about3,
            title: "Save Time with Professional Quality",
            desc: "Our expert team delivers professionally composed songs quickly, letting you enjoy your personal melody sooner."
        },
    ];


    return (
        <>
            <div className={classlist}>
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                        Bring Your Story to Life with a Unique <span className="bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text">Melody</span>
                    </h3>

                    <p className="text-slate-400 max-w-xl mx-auto">
                    Imagine gifting your loved one a personalized song that tells your story, captures your memories, and becomes a treasure they’ll cherish forever. From romantic ballads to upbeat tunes, we create custom songs for gifts that make every occasion unforgettable.
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featuresData.map((item, index) => (
                        <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800" key={index}>
                            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full">
                                <img src={item.image} className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1" alt="Feature Image" />
                            </div>

                            <div className="p-6">
                                <h5 className="text-lg font-semibold">{item.title}</h5>
                                <p className="text-slate-400 mt-3">{item.desc}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client1 from "../assets/images/client/01.jpg"
import client2 from "../assets/images/client/02.jpg"
import client3 from "../assets/images/client/03.jpg"
import client4 from "../assets/images/client/04.jpg"
import client5 from "../assets/images/client/05.jpg"
import client6 from "../assets/images/client/06.jpg"
import short_video from '../assets/images/new/short_video.png'
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import { FiCheckCircle } from "../assets/icons/vander"
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/css/modal-video.css'
import Navbar from "../components/navbar";
import SEO from "../components/SEO";


export default function AboutUs() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }, []);
    const [isOpen, setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0)
    const teamData = [
        {
            image: client1,
            name: "Calvin Carlo",
            title: 'C.E.O'
        },
        {
            image: client2,
            name: "Aliana Rosy",
            title: 'Co-founder'
        },
        {
            image: client3,
            name: "Sofia Razaq",
            title: 'C.O.O.'
        },
        {
            image: client4,
            name: "Ronny Jofra",
            title: 'Director'
        },
        {
            image: client5,
            name: "Cristina Murphy",
            title: 'Manager'
        },
        {
            image: client6,
            name: "Jimmi Shaa",
            title: 'Operator'
        },
    ]
    return (
        <>
            <SEO
                title="About Us | AV Custom Song"
                description="Learn more about AV Custom Song, your partner in creating personalized music for every special occasion."
                keywords="about AV Custom Song, custom music, personalized gifts"
            />
            <Navbar />
            <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/bg-banner.webp')] bg-no-repeat bg-bottom bg-cover">
                <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <div>
                            <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">About Us</h5>
                        </div>

                        {/* <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Mortal.Ai</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">About Us</li>
                    </ul> */}
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:py-24 py-16">

                <div className="container relative">
                    <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                        <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:m-auto after:w-96 after:h-96 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800 lg:me-6">
                            <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                                <img src={short_video} alt="" />

                                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                    <Link to="#!" onClick={() => setOpen(true)} className="lightbox lg:h-16 h-14 lg:w-16 w-14 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 hover:bg-amber-400 dark:hover:bg-amber-400 text-amber-400 hover:text-white duration-500 ease-in-out mx-auto">
                                        <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <ModalVideo
                            channel="youtube"
                            youtube={{ mute: 0, autoplay: 0 }}
                            isOpen={isOpen}
                            videoId="o1oPCQy9Cc0"
                            onClose={() => setOpen(false)}
                        />
                        <div className="">
                            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span className="font-bold">Experience the Magic</span> <br /> of Music Creation</h3>
                            <p className="text-slate-400 max-w-xl">"Our music isn't just about sound; it's about capturing emotion, energy, and the soul of every song we create."</p>

                            <ul className="list-none text-slate-400 mt-4">
                                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Original Compositions for Every Genre</li>
                                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Collaborating with Talented Artists Worldwide</li>
                                <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Bringing Music to Life with Cutting-Edge Technology</li>
                            </ul>

                            <div className="mt-4">
                                <Link to="/about-details" className="hover:text-amber-400 font-medium duration-500">Discover More <i className="mdi mdi-chevron-right text-[20px] align-middle"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="container relative md:mt-24 mt-16">
                    <div className="lg:flex justify-center">
                        <div className="lg:w-4/5">
                            <ul className="md:flex inline-block w-fit mx-auto flex-wrap justify-center text-center p-2 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                    <button className={`${activeIndex === 0 ? "text-white bg-amber-400" : "hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(0)}>
                                        <h5 className="text-base font-semibold">Song Creation</h5>
                                        <p className="text-sm mt-1">Experience the process of turning raw emotion into a beautiful composition.</p>
                                    </button>
                                </li>
                                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                    <button className={`${activeIndex === 1 ? "text-white bg-amber-400" : "hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(1)}>
                                        <h5 className="text-base font-semibold">Collaborations</h5>
                                        <p className="text-sm mt-1">Join us as we collaborate with artists from all over the world.</p>
                                    </button>
                                </li>
                                <li role="presentation" className="inline-block md:w-1/3 w-full p-2">
                                    <button className={`${activeIndex === 2 ? "text-white bg-amber-400" : "hover:text-amber-400 dark:hover:text-white hover:bg-gray-50 dark:hover:bg-slate-800"} px-5 py-3 text-start rounded-md w-full duration-500 `} onClick={() => setActiveIndex(2)}>
                                        <h5 className="text-base font-semibold">Live Performances</h5>
                                        <p className="text-sm mt-1">Feel the energy and passion of our live shows, where music comes to life.</p>
                                    </button>
                                </li>
                            </ul>

                            <div id="StarterContent" className="mt-6">
                                {activeIndex === 0 ?
                                    <div>
                                        <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                                            <img src={image1} className="rounded-t-lg" alt="" />
                                        </div>
                                    </div> : ''
                                }
                                {activeIndex === 1 ?
                                    <div>
                                        <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                                            <img src={image2} className="rounded-t-lg" alt="" />
                                        </div>
                                    </div> : ''
                                }
                                {activeIndex === 2 ?
                                    <div>
                                        <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 px-6 pt-6">
                                            <img src={image3} className="rounded-t-lg" alt="" />
                                        </div>
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                </div> */}
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
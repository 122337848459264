import React from 'react';
import { Helmet } from 'react-helmet';

const SEO = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="author" content="AV Custom Song" />
      <meta name="robots" content="index, follow" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="AV Custom Song" />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:image" content="https://avcustomsong.com/static/media/logo1.7db41c0d8766d09bee95.png" />
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default SEO;

import React, { useState } from "react";
import { Link } from "react-router-dom";

import { MdKeyboardArrowDown } from '../assets/icons/vander';

export default function Faq() {
    const [activeIndex, setActiveIndex] = useState(1);

    const accordionData = [
        {
            id: 1,
            title: 'Share Your Story',
            desc: "Tell us about the person or event and any details you'd like included."
        },
        {
            id: 2,
            title: 'Choose Your Style',
            desc: 'Select the music genre, tone, and vibe that suit your occasion.'
        },
        {
            id: 3,
            title: 'We Compose and Record',
            desc: "Our talented team of songwriters and musicians will craft a song that’s uniquely yours."
        },
        {
            id: 4,
            title: 'Receive Your Custom Song',
            desc: "Get your professionally recorded song in high-quality audio format, ready to share and enjoy!"
        },
    ];

    return (
        <>
            <div className="container relative md:mt-24 mt-16">
                    <div className="lg:col-span-4 md:mb-0 mb-8">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-center text-2xl leading-normal font-semibold">How It Works</h3>
                        <p className="text-slate-400 max-w-xl text-center mx-auto mb-6">Creating your personalized song is simple</p>
                        {/* <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-amber-400 border-gray-100 dark:border-gray-800 hover:border-amber-400 dark:hover:border-amber-400 text-slate-900 dark:text-white hover:text-white rounded-md">Reach Out to Us</Link> */}
                    </div>
                <div className="grid lg:grid-cols-1 md:grid-cols-1 grid-cols-1 items-center md:gap-[30px]">

                    <div className="lg:col-span-8 md:mt-0 mt-8">
                        {accordionData.map((item, index) => {
                            return (
                                <div className="relative shadow dark:shadow-gray-800 rounded-md overflow-hidden mt-4" key={index}>
                                    <h2 className="text-base font-semibold" id="accordion-collapse-heading-1">
                                        <button type="button" onClick={() => setActiveIndex(item.id)} className={`${activeIndex === item.id ? "bg-gray-50 dark:bg-slate-800 text-amber-400" : ""} flex justify-between items-center p-5 w-full font-medium text-start`}>
                                            <span>{item.title}</span>
                                            <MdKeyboardArrowDown className={`${activeIndex === item.id ? "rotate-180" : ""} w-4 h-4 shrink-0`} />
                                        </button>
                                    </h2>
                                    <div className={activeIndex === item.id ? "" : "hidden"}>
                                        <div className="p-5">
                                            <p className="text-slate-400 dark:text-gray-400">{item.desc}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}

import React from "react";
import amazon from "../assets/images/client/amazon.svg"
import google from "../assets/images/client/google.svg"
import lenovo from "../assets/images/client/lenovo.svg"
import paypal from "../assets/images/client/paypal.svg"
import shopify from "../assets/images/client/shopify.svg"
import spotify from "../assets/images/client/spotify.svg"

import girlSing from "../assets/images/new/girl_sing.jpg";
import girlSing2 from "../assets/images/new/girl_sing2.jpg";
import girlSing3 from "../assets/images/new/girl_sing3.jpg";
import girlSing4 from "../assets/images/new/girl_sing4.jpg";
import girlSing5 from "../assets/images/new/girl_sing5.jpg";
import girlSing6 from "../assets/images/new/girl_sing6.jpg";
import { Link } from "react-router-dom";

export default function BrandLogo() {
    const brandLogo = [amazon, google, lenovo, paypal, shopify, spotify]
    const languages = ['English', 'Tamil', 'Telugu', 'Kannada', 'Malayalam', 'Hindi']
    const countryData = [
        { name: "English", image: girlSing },
        { name: "Tamil", image: girlSing2 },
        { name: "Telugu", image: girlSing3 },
        { name: "Malayalam", image: girlSing4 },
        { name: "Kannada", image: girlSing5 },
        { name: "Hindi", image: girlSing6 },
    ];
    return (
        <div className="container relative">
            {/* <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-6">
                {languages.map((item, index) => {
                    return (
                        <div className="mx-auto py-4" key={index}>
                            <img src={item} className="h-6" alt=""/>
                            <p className="text-md text-gray-500 uppercase">{item}</p>
                        </div>
                    )
                })}
            </div> */}
            <div className="grid md:grid-cols-6 grid-cols-2 justify-center gap-6">
                {countryData.map((item, index) => (
                    <Link to="" className="py-[6px] px-2 flex items-center justify-center space-x-4 tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold m-0.5" key={index}>
                        <div><img src={item.image} className="h-5 w-5 me-1 rounded-full" alt="" /></div>
                        <div><p>{item.name}</p></div>
                    </Link>
                ))}
            </div>
        </div>
    )
}
import React from "react";
import { FiMusic, FiEdit2, FiHeart, FiMic, FiGlobe, FiLifeBuoy } from "../assets/icons/vander";

export default function AiFeatures() {
    const featureData = [
        {
            icon: FiMusic,
            title: "AI-Powered Song Composer",
            desc: "Leverage AI to transform your emotions and stories into unique melodies crafted just for you.",
        },
        {
            icon: FiEdit2,
            title: "Lyrics Personalization",
            desc: "Customize your song’s lyrics to perfectly reflect your memories, occasions, or messages.",
        },
        {
            icon: FiHeart,
            title: "Emotion-Based Composition",
            desc: "Select your desired mood, from joyful and celebratory to calm and nostalgic, for a truly heartfelt experience.",
        },
        {
            icon: FiMic,
            title: "Professional Vocal Options",
            desc: "Choose from a variety of vocal styles or upload your voice for a completely personalized touch.",
        },
        {
            icon: FiGlobe,
            title: "Multi-Lingual Support",
            desc: "Create songs in your preferred language, with options to blend multiple languages seamlessly.",
        },
        {
            icon: FiLifeBuoy,
            title: "Dedicated Support",
            desc: "Our team is here to assist you throughout your song creation journey, ensuring an effortless experience.",
        },
    ];

    return (
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                        AI-Powered Custom Song Creation
                    </h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                        Turn your cherished moments into beautiful melodies with the power of AI. Discover features designed to bring your stories to life through music.
                    </p>
                </div>

                <div className="grid md:grid-cols-3 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item, index) => {
                        const Icon = item.icon;
                        return (
                            <div className="group flex duration-500 xl:p-3" key={index}>
                                <div className="flex align-middle justify-center items-center w-14 h-14 mt-1 bg-amber-400/5 group-hover:bg-amber-400 group-hover:text-white border-2 border-amber-400/20 text-amber-400 rounded-lg text-2xl shadow-sm dark:shadow-gray-800 duration-500">
                                    <Icon className="w-5 h-5" />
                                </div>
                                <div className="flex-1 ms-4">
                                    <h4 className="mb-0 text-lg font-semibold">{item.title}</h4>
                                    <p className="text-slate-400 mt-2">{item.desc}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
}

import React from "react";
import { Link } from "react-router-dom";

export default function AmazingFeatures() {
    const featureData = [
        {
            icon: "mdi mdi-music-note",
            title: 'Personalized Song Creation',
            desc: 'Turn your story into a song crafted uniquely for you, capturing your personal memories and experiences.'
        },
        {
            icon: "mdi mdi-video",
            title: 'Custom Song With Video',
            desc: 'Create a song and video that beautifully reflects your story, ensuring every lyric resonates with your emotions and experiences.'
        },
        {
            icon: "mdi mdi-headset",
            title: 'Custom Audio Options',
            desc: 'Choose your preferred music style, genre, and tempo to make your song truly yours.'
        },
    ];
    

    return (
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">What we do</h3>

                    <p className="text-slate-400 max-w-2xl mx-auto">
                        Create songs that tell your unique story. We capture your emotions and memories!
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featureData.map((item, index) => (
                        <div
                            className="px-6 py-10 shadow hover:shadow-md dark:shadow-gray-800 dark:hover:shadow-gray-700 duration-500 rounded-lg bg-white dark:bg-slate-900"
                            key={index}
                        >
                            <i className={`${item.icon} text-4xl bg-gradient-to-tl to-amber-400 from-fuchsia-600 text-transparent bg-clip-text`}></i>

                            <div className="content mt-7">
                                <Link to="" className="title h5 text-lg font-medium hover:text-amber-400 duration-500">
                                    {item.title}
                                </Link>
                                <p className="text-slate-400 mt-3">{item.desc}</p>

                                <div className="mt-5">
                                    <Link to="" className="hover:text-amber-400 font-medium duration-500">
                                        Read More <i className="mdi mdi-arrow-right align-middle"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

import React from "react";
import { Link } from "react-router-dom";

import aboutImg from "../assets/images/features/2.png";

import { FiCheckCircle, MdKeyboardArrowRight } from '../assets/icons/vander';

export default function AboutTwo() {
    return (
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative order-1 md:order-2">
                        <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30 from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 pe-6 pt-6 lg:ms-8">
                            <img src={aboutImg} className="ltr:rounded-tr-lg rtl:rounded-tl-lg" alt="Personalized Story-Based Song Creation" />
                        </div>
                    </div>

                    <div className="order-2 md:order-1">
                        <h4 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
                            Turn Your Story into a Song
                        </h4>
                        <p className="text-slate-400">
                            "Imagine your most cherished memories captured in a song. With AV Custom Song, you can transform your life stories, love letters, or unique experiences into unforgettable music that resonates with you and others."
                        </p>
                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Custom songs that tell your personal story</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Share your life moments through music</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> High-quality audio crafted just for you</li>
                        </ul>

                        <div className="mt-4">
                            <Link to="/learn-more" className="hover:text-amber-400 font-medium duration-500 inline-flex items-center">
                                Find Out More <MdKeyboardArrowRight className="ms-1 text-[20px]" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

import React, { useEffect, useState } from 'react';
import { AiOutlineArrowUp } from 'react-icons/ai';
import { RiWhatsappFill } from 'react-icons/ri';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll';
import { FiMoon, FiSun, AiOutlineWhatsApp } from '../assets/icons/vander';
import TawkTo from '../Chatbot/TawkTo';

export default function Switcher() {
    const [scrollTop, setScrollTop] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScrollTop(window.scrollY > 50);
        });
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const scrollToTop = () => {
        scroll.scrollToTop({
            duration: 500,
            smooth: true,
        });
    };

    const openWhatsApp = () => {
        const phoneNumber = "7810896713"; // Replace with your phone number (include country code without '+')
        const message = encodeURIComponent(`Hi! I loved Echoes of Harmony. Can you share more info?`);
        const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;
        window.open(whatsappUrl, '_blank');
    };

    function changeMode(mode, event) {
        switch (mode) {
            case 'mode':
                if (document.documentElement.className.includes("dark")) {
                    document.documentElement.className = 'light';
                } else {
                    document.documentElement.className = 'dark';
                }
                break;
            case 'layout':
                if (event.target?.innerText === "LTR") {
                    document.documentElement.dir = "ltr";
                } else {
                    document.documentElement.dir = "rtl";
                }
                break;

            default:
                break;
        }
    }

    return (
        <>
            <div
                className="fixed top-1/3 -right-2 z-50 flex bg-green-400 items-center justify-center w-12 h-12  rounded-full shadow-lg cursor-pointer"
                onClick={openWhatsApp}
                title="Chat on WhatsApp"
            >
                <RiWhatsappFill size={40} className="text-green-400" />
            </div>

            <Link
                to="#"
                onClick={scrollToTop}
                id="back-to-top"
                className={`${scrollTop ? 'back-to-top fixed flex items-center justify-center text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-amber-400 hover:bg-amber-500 text-white leading-9' : 'none'}`}
            >
                <AiOutlineArrowUp />
            </Link>
            <TawkTo />
        </>
    );
}

import React from 'react';
import Navbar from '../components/navbar';
import SEO from '../components/SEO';
import { FiCheckCircle } from 'react-icons/fi';
import Footer from '../components/footer';

const AboutPage = () => {
    return (
        <>
            <SEO
                title="About Us Detail Page | AV Custom Song"
                description="Learn more about AV Custom Song, your partner in creating personalized music for every special occasion."
                keywords="about AV Custom Song, custom music, personalized gifts"
            />
            <Navbar />
            <div className="container relative md:py-44 py-32">
                {/* Header Section */}
                <div className="text-center mb-12">
                    <h1 className="text-3xl font-bold text-gray-900">Custom Song Services</h1>
                    <p className="mt-6 text-md text-gray-400">
                        Transform your cherished moments into timeless melodies with AV Custom Song. Our personalized song creation service is designed to bring your stories to life through music. Whether it’s for a wedding, birthday, anniversary, or even a corporate event, our team of skilled songwriters and musicians crafts music that perfectly captures your emotions. A custom song isn’t just a gift—it’s a memory that lasts forever.
                    </p>
                </div>

                {/* Why Choose Us Section */}
                <div className="mb-12">
                    <h2 className="text-xl font-bold text-gray-700 mb-6 mt-8">Why Choose AV Custom Song?</h2>
                    <ul className="list-disc pl-8 text-md space-y-4 text-lg text-gray-400">
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Personalized Music Gifts:</strong> Give a truly unique and meaningful gift tailored to your loved one.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Custom Wedding Songs:</strong> Create the perfect soundtrack for your big day, from the aisle to the dance floor.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Anniversary Song Gifts:</strong> Celebrate your love story with a melody that captures every moment.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Birthday Song Customization:</strong> Surprise someone special with a song made just for them.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Romantic Songs for Loved Ones:</strong> Share your feelings with a melody that speaks from the heart.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Corporate Jingles and Branding Songs:</strong> Stand out with professional, customized jingles for your business or event.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Special Occasion Songs:</strong> From graduations to retirements, mark every milestone with music.</li>
                    </ul>
                </div>

                {/* Benefits Section */}
                <div className="mb-12">
                    <h2 className="text-xl font-bold text-gray-700 mb-6 mt-8">Benefits of Choosing Our Service</h2>
                    <ul className="list-disc pl-8 text-md space-y-4 text-lg text-gray-400">
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Creative and Unique:</strong> No templates, just original music inspired by your story.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Affordable Options:</strong> High-quality songs at prices that suit your budget.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Professional Quality:</strong> Collaborate with experienced songwriters and musicians.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Quick Turnaround:</strong> Receive your custom song in time for your special event.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Easy Process:</strong> Share your story, choose a style, and let us do the rest!</li>
                    </ul>
                </div>

                {/* Occasions Section */}
                <div className="mb-12">
                    <h2 className="text-xl font-bold text-gray-700 mb-6 mt-8">Occasions We Cater To</h2>
                    <ul className="list-disc pl-8 text-md space-y-4 text-lg text-gray-400">
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Weddings:</strong> From first dances to heartfelt vows.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Birthdays:</strong> Make someone’s day unforgettable.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Anniversaries:</strong> A beautiful way to honor your journey together.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Corporate Events:</strong> Memorable jingles and team anthems.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Special Moments:</strong> For “just because” gifts that leave a lasting impression.</li>
                    </ul>
                </div>

                {/* How to Get Started Section */}
                <div>
                    <h2 className="text-xl font-bold text-gray-700 mb-6 mt-8">How to Get Started</h2>
                    <ol className="list-decimal pl-8 text-md space-y-4 text-lg text-gray-400">
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Contact Us:</strong> Share details about your event or loved one.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Discuss Your Vision:</strong> Choose the tone, style, and lyrics that resonate with your story.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Song Creation:</strong> We compose, write, and produce your personalized song.</li>
                        <li className='flex items-center'><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /><strong>Delivery:</strong> Receive your custom track as an MP3 or other formats, ready to gift or play.</li>
                    </ol>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutPage;
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import client1 from "../assets/images/client/01.jpg";
import blog1 from '../assets/images/blog/1.jpg';

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { blogData } from "../data/data";

import { FiMail, FiFacebook, FiInstagram, BsYoutube, FiFileText } from '../assets/icons/vander';
import SEO from "../components/SEO";

export default function BlogDetails() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }, []);

    // const params = useParams();
    // const id = params.id;
    // const data = blogData.find((blog) => blog.id === parseInt(id));

    const params = useParams();
    const slug = params.slug;
    const data = blogData.find((blog) => blog.slug === slug);


    return (
        <>
            <SEO
                title={`${data?.title} | Blog | AV Custom Song`}
                description={data?.description}
                keywords={`custom songs, ${(data?.keywords)}`}
            />
            <Navbar />
            <section className="relative md:pt-44 pt-36 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="lg:w-2/3 md:w-4/5">
                            <h1 className="md:text-4xl text-3xl font-bold md:tracking-normal tracking-normal md:leading-normal leading-normal mt-3">
                                {data?.title ? data?.title : "Song Title Not Found"}
                            </h1>

                            <div className="flex items-center mt-5">
                                <img src={data?.client ? data?.client : client1} className="h-12 w-12 rounded-full bg-white" alt="" />

                                <div className="ms-2">
                                    <h6>
                                        <Link to="" className="font-medium hover:text-amber-400">{data?.author ? data?.author : "Unknown Artist"}</Link>
                                        {/* <Link to="" className="ms-1 text-green-600 font-medium"><i className="mdi mdi-circle-medium"></i>Follow</Link> */}
                                    </h6>
                                    <span className="text-slate-400 text-sm">{data?.date ? data?.date : "Unknown Date"}
                                        {/* <span><i className="mdi mdi-circle-medium"></i>6 min read</span> */}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="relative md:pb-24 pb-16 pt-7">
                <div className="container relative">
                    <div className="md:flex justify-center">
                        <div className="lg:w-2/3 md:w-4/5">
                            <img src={data?.image ? data?.image : blog1} className="rounded-md" alt="" />

                            {/* Song Description */}
                            <p className="text-slate-400 mt-4">The song explores various themes and emotions, offering a deep dive into the artist's vision.</p>

                            {/* Blog Points */}
                            <div className="mt-8">
                                <h6 className="text-xl font-semibold">Objectives to cover:</h6>
                                <ul className="list-disc pl-6 mt-2">
                                    {data?.points?.map((point, index) => (
                                        <li key={index} className="text-slate-400 mb-4"><span className="ml-2">{index + 1}. </span> <span>{point}</span></li>
                                    ))}
                                </ul>
                            </div>
                            <div>
                                <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Song writing Journey</Link>
                            </div>

                            <div className="mt-6">
                                <ul className="flex justify-between">
                                    <li className="inline ml-4"><Link to="https://www.facebook.com/profile.php?id=61567696507143" target="_blank" className="px-4 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiFacebook className="h-6 w-6 align-middle" /> <span className="px-2">Facebook</span></Link></li>
                                    <li className="inline"><Link to="https://www.instagram.com/av_custom_song/" target="_blank" className="h-6 w-6 px-4 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiInstagram className="h-6 w-6 align-middle" /><span className="px-2">Instagram</span></Link></li>
                                    <li className="inline"><Link to="https://www.youtube.com/@AVCustomsong/featured" target="_blank" className="h-6 w-6 px-4 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><BsYoutube className="h-6 w-6 align-middle" /><span className="px-2">Youtube</span></Link></li>
                                    <li className="inline"><Link to="mailto:support@avcustomsong.com" target="_blank" className="h-6 w-6 px-4 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiMail className="h-6 w-6 align-middle" /><span className="px-2">Mail</span></Link></li>
                                    <li className="inline"><Link to="https://forms.gle/eVijLxL1GVGEtvVA8" target="_blank" className="h-6 w-6 px-4 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiFileText className="h-6 w-6 align-middle" /><span className="px-2">Forms</span></Link></li>
                                </ul>
                            </div>

                            {/* Comment Section */}
                            {/* <div className="mt-8">
                                <h6 className="text-lg font-semibold">Comments:</h6>
                                {data?.comments?.map((comment) => (
                                    <div key={comment.id} className="flex items-center mt-4">
                                        <img src={comment.avatar ? comment.avatar : client2} className="h-6 w-6 rounded-full" alt="" />
                                        <div className="ms-3">
                                            <h6 className="font-medium">{comment.author}</h6>
                                            <span className="text-slate-400 text-sm">{comment.date}</span>
                                            <p className="text-slate-400 mt-2">{comment.message}</p>
                                        </div>
                                    </div>
                                ))}
                            </div> */}

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
            <Switcher />
        </>
    );
}

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FiCheckCircle, AiOutlineClose } from '../assets/icons/vander';

export default function Pricing() {
    const [businessPrice, setBusinessPrice] = useState(20);
    const [professionalPrice, setProfessionalPrice] = useState(40);

    let businessUpdate = parseFloat(businessPrice * 0.05).toFixed(1);
    let professionalUpdate = parseFloat(professionalPrice * 0.025).toFixed(1);

    const plans = [
        {
            name: "Basic",
            description: "For anyone to embark on the journey of AI video creation",
            price: 450,
            priceId: "",
            priceInput: "",
            linkText: "Start Your Journey",
            trialInfo: "No credit card required. Free 14-day trial",
            features: [
                { text: "Song Duration 1 to 2 mins", available: true },
                { text: "2 to 3 Para Lyrics", available: true },
                { text: "3 Corrections", available: true }
                // { text: "Limited media library", available: false },
                // { text: "Premium stock footage", available: false }
            ]
        },
        {
            name: "Standard",
            description: "For creators ready to make their mark",
            price: 950,
            priceId: "business-amount",
            priceInput: "business-price",
            linkText: "Take the Leap",
            onPriceChange: (e) => setBusinessPrice(e.target.value),
            update: businessUpdate,
            features: [
                { text: "Song Duration 2 to 3.5 mins", available: true },
                { text: "3 to 5 Para Lyrics", available: true },
                { text: "5 Corrections", available: true },
                // { text: "Upto 1 user in the creative space", available: true },
                // { text: "40/mo premium iStock access", available: true }
            ]
        },
        {
            name: "Permium",
            description: "For the creators who are shaping the future",
            price: 1750,
            priceId: "professional-amount",
            priceInput: "professional-price",
            linkText: "Shape the Future",
            onPriceChange: (e) => setProfessionalPrice(e.target.value),
            update: professionalUpdate,
            features: [
                { text: "Song Duration 4 to 5 mins", available: true },
                { text: "Custom song plus video", available: true },
                { text: "MAX 8 Para Lyrics", available: true },
                { text: "8 Corrections", available: true },
                // { text: "Access to 250 premium iStock files/mo", available: true }
            ]
        }
    ];


    return (
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
            {plans.map((plan, index) => (
                <div key={index} className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                    <div className="p-6 border border-gray-800 text-center">
                        <h5 className="text-2xl leading-normal font-semibold">{plan.name}</h5>
                        <div className="relative">
                            <div className="flex mt-4 justify-center">
                                <span className="text-lg font-semibold">₹</span>
                                <span className="text-5xl font-semibold mb-0 ms-1" id={plan.priceId}>{plan.price}</span>
                                <p className="text-slate-400 uppercase text-xs">per song</p>
                            </div>
                        </div>
                    </div>
                    <div className="p-6 bg-gray-50 dark:bg-slate-800 h-full">
                        <ul className="list-none text-slate-400">
                            <li className="font-semibold text-slate-900 dark:text-white text-sm uppercase">Features:</li>
                            {plan.features.map((feature, idx) => (
                                <li key={idx} className="flex items-center mt-2">
                                    {feature.available ? (
                                        <FiCheckCircle className="text-green-600 h-[18px] w-[18px] me-2" />
                                    ) : (
                                        <AiOutlineClose className="h-[18px] w-[18px] me-2 text-slate-400" />
                                    )}
                                    <span className={`${feature.available ? "text-slate-900 dark:text-white font-semibold" : "text-slate-400"}`}>{feature.text}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            ))}
        </div>
    );
}

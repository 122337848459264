import React, { useState } from "react";
import { Link } from "react-router-dom";

import germany from '../assets/images/flags/germany.png';
import india from '../assets/images/flags/india.png';
import italy from '../assets/images/flags/italy.png';
import russia from '../assets/images/flags/russia.png';
import spain from '../assets/images/flags/spain.png';
import usa from '../assets/images/flags/usa.png';
import videoImg from "../assets/images/modern.mp4";
import girlSing from "../assets/images/new/girl_sing.jpg";
import girlSing2 from "../assets/images/new/girl_sing2.jpg";
import girlSing3 from "../assets/images/new/girl_sing3.jpg";
import girlSing4 from "../assets/images/new/girl_sing4.jpg";
import girlSing5 from "../assets/images/new/girl_sing5.jpg";
import girlSing6 from "../assets/images/new/girl_sing6.jpg";
import video_banner from "../assets/images/new/video_banner.png";

import { FiCheckCircle } from '../assets/icons/vander';
import ModalVideo from 'react-modal-video';
import '../../node_modules/react-modal-video/css/modal-video.css';

export default function AboutThree() {
    const [isOpen, setOpen] = useState(false);
    const videoUrl = "https://youtube.com/shorts/o1oPCQy9Cc0?si=8cDdorX8degU5Pm5";

    const countryData = [
        { name: "Tamil", image: girlSing2 },
        { name: "Telugu", image: girlSing3 },
        { name: "Kannada", image: girlSing5 },
        { name: "Malayalam", image: girlSing4 },
        { name: "Hindi", image: girlSing6 },
        { name: "English", image: girlSing },
    ];

    return (
        <>
            <div className="container relative md:mt-24 mt-16">
                <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
                    <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800">
                        <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                            <div className="relative">
                                {/* <video controls autoPlay loop>
                                    <source src={videoImg} type="video/mp4" />
                                </video> */}
                                <div>
                                    <img src={video_banner} alt="video banner" />
                                </div>
                                {/* <Link to="" className="absolute top-2 start-2 rounded-full p-0.5 bg-white dark:bg-slate-900 shadow dark:shadow-slate-800 z-10">
                                    <img src={germany} className="h-7 w-7 rounded-full" alt="" />
                                </Link> */}
                            </div>
                            <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                <Link
                                    to=""
                                    onClick={() => setOpen(true)}
                                    className="lightbox lg:h-16 h-14 lg:w-16 w-14 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 hover:bg-amber-400 dark:hover:bg-amber-400 text-amber-400 hover:text-white duration-500 ease-in-out mx-auto"
                                >
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link>
                            </div>
                        </div>

                        <div className="mt-6">
                            {countryData.map((item, index) => (
                                <Link to="" className="py-[6px] px-2 inline-flex items-center tracking-wider align-middle duration-500 text-sm text-center rounded bg-amber-400/5 hover:bg-amber-400 border border-amber-400/10 hover:border-amber-400 text-amber-400 hover:text-white font-semibold m-0.5" key={index}>
                                    <img src={item.image} className="h-5 w-5 me-1 rounded-full" alt="" />
                                    {item.name}
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className="">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Perfect Songs for Every Occasion</h3>
                        <p className="text-slate-400 max-w-xl">"Capture your memories in a unique song crafted just for you. With a touch of storytelling and personalization, relive special moments through music that speaks to you."</p>

                        <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Anniversaries: Celebrate your milestones with a custom anniversary song gift that captures your journey.</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Birthdays: Make their day special with a unique birthday song gift idea they’ll love.</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Weddings: Transform your special moments into memories with a custom wedding song.
                            </li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Romantic Moments: Express your feelings with a custom love song that speaks directly from your heart.
                            </li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2" /> Corporate Events: Create a lasting impression with a tailored corporate jingle or event song.
                            </li>
                        </ul>

                        {/* <div className="mt-4">
                            <Link to="" className="hover:text-amber-400 font-medium duration-500">Find Out More <i className="mdi mdi-chevron-right text-[20px] align-middle"></i></Link>
                        </div> */}
                    </div>
                </div>
            </div>

            <ModalVideo
                channel="youtube"
                youtube={{ mute: 0, autoplay: 0 }}
                isOpen={isOpen}
                videoId="cC9QZ3Nfw-k"
                onClose={() => setOpen(false)}
            />
        </>
    );
}

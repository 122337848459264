import React, { useEffect } from "react";
import Pricing from "../components/pricing"
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import Navbar from "../components/navbar";
import { Helmet } from "react-helmet";
import SEO from "../components/SEO";

export default function Pricings() {
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
    }, []);

    return (
        <>
            <SEO
                title="Pricing | AV Custom Song"
                description="Explore our affordable pricing plans for personalized songs. Choose the perfect package for your special moments."
                keywords="pricing for custom songs, affordable song creation, personalized music costs"
            />
            <Navbar />
            <section className="relative md:py-44 py-32 bg-[url('../../assets/images/bg/bg-banner.webp')] bg-no-repeat bg-bottom bg-cover">
                <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center mt-6">
                        <div>
                            <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">Pricing Plans</h5>
                        </div>

                        {/* <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white"><Link to="/">Mortal.Ai</Link></li>
                        <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><i className="mdi mdi-chevron-right"></i></li>
                        <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white" aria-current="page">Pricing</li>
                    </ul> */}
                    </div>
                </div>
            </section>
            <div className="relative">
                <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
                    <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                    </svg>
                </div>
            </div>

            <section className="relative md:pb-24 pb-16">
                {/* <AboutThree/> */}
                <div className="container relative md:mt-24 mt-16">
                    <div className="grid grid-cols-1 pb-6 text-center">
                        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Customized Songs for Every Story</h3>

                        <p className="text-slate-400 max-w-xl mx-auto">Create a song as unique as your story. Our service gives you an affordable, quick, and personalized music experience that brings your vision to life.</p>
                    </div>
                    <Pricing />
                </div>
                {/* <ClientsTwo/> */}
            </section>
            <Footer />
            <Switcher />
        </>
    )
}
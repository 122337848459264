
import client2 from '../assets/images/client/02.jpg'
import client5 from '../assets/images/client/05.jpg'
import client8 from '../assets/images/client/08.jpg'
import logo from '../assets/images/new/logo1.png'
import img1 from '../assets/images/new/Blog/1.png'
import img2 from '../assets/images/new/Blog/2.png'
import img3 from '../assets/images/new/Blog/3.png'

export const blogData = [
  {
    id: 1,
    title: "The Story Behind 'Echoes of Harmony'",
    slug: "the-story-behind-echoes-of-harmony",
    author: "AV Custom Song",
    client: logo,
    date: "Nov 04, 2024",
    image: img1, // Replace with your song's image
    description:
      "The Story Behind 'Echoes of Harmony' - A journey of self-discovery through music. Explore how blending traditional melodies with modern sounds, how each verse reflects different emotions in life, collaborating with musicians from around the world, and the inspiration behind the lyrics: personal experiences.",
    keywords:
      "a journey, self discovery, blending traditional, modern sounds, each verse, different emotions, collaborating with, musicians from, inspiration behind, personal experiences",
    comments: [
      {
        id: 1,
        author: "Sam Listener",
        avatar: client2,
        date: "Nov 04, 2024",
        message: "I love the depth of emotions in this song!",
      },
    ],
    points: [
      "A journey of self-discovery through music.",
      "Blending traditional melodies with modern sounds.",
      "How each verse reflects different emotions in life.",
      "Collaborating with musicians from around the world.",
      "The inspiration behind the lyrics: personal experiences."
    ]
  },
  {
    id: 2,
    title: "Creating Magic with 'Rhythmic Waves'",
    slug: "creating-magic-with-rhythmic-waves",
    author: "AV Custom Song",
    client: logo,
    date: "Nov 05, 2024",
    image: img2, // Replace with your song's image
    description:
      "Creating Magic with 'Rhythmic Waves' - A fusion of electronic and acoustic elements. Explore how incorporating experimental rhythms that challenge traditional song structures, using nature sounds to enhance the listening experience, the meaning behind the waves: metaphor for life's ups and downs, and creating the track using unconventional instruments.",
    keywords:
      "a fusion, electronic acoustic, incorporating experimental, rhythms that, using nature, sounds to, meaning behind, metaphor for, creating the, unconventional instruments",
    comments: [
      {
        id: 1,
        author: "Chris Grooves",
        avatar: client5,
        date: "Nov 5, 2024",
        message: "This song always puts me in a great mood!",
      },
    ],
    points: [
      "A fusion of electronic and acoustic elements.",
      "Incorporating experimental rhythms that challenge traditional song structures.",
      "Using nature sounds to enhance the listening experience.",
      "The meaning behind the waves: metaphor for life's ups and downs.",
      "Creating the track using unconventional instruments."
    ]
  },
  {
    id: 3,
    title: "Exploring the Melodies of 'Whispers of Time'",
    slug: "exploring-the-melodies-of-whispers-of-time",
    author: "AV Custom Song",
    client: logo,
    date: "Nov 06, 2024",
    image: img3, // Replace with your song's image
    description:
      "Exploring the Melodies of 'Whispers of Time' - A reflection on the passage of time through musical composition. Explore how using minimalism in music to evoke deep emotions, the blend of classical and contemporary instruments, the personal story behind the creation of 'Whispers of Time', and how each note represents a different memory.",
    keywords:
      "a reflection, passage of, using minimalism, music to, the blend, classical contemporary, personal story, behind the, each note, different memory",
    comments: [
      {
        id: 1,
        author: "Mike Symphony",
        avatar: client8,
        date: "Nov 06, 2024",
        message: "This blog beautifully captures the essence of the song.",
      },
    ],
    points: [
      "A reflection on the passage of time through musical composition.",
      "Using minimalism in music to evoke deep emotions.",
      "The blend of classical and contemporary instruments.",
      "The personal story behind the creation of 'Whispers of Time'.",
      "How each note represents a different memory."
    ]
  },
];
